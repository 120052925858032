import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; // استخدام الترجمة

const Detail = () => {
  const { t } = useTranslation(); // دالة الترجمة
  const { id } = useParams(); // الحصول على المعرف من الـ URL

  // البيانات مع مفاتيح الترجمة
  const Data = [
    {
      id: 1,
      img: require("../image/location.png"),
      title: t("our_location"),
      description: t("location_desc"),
    },
    {
      id: 2,
      img: require("../image/bank.png"),
      title: t("establishment_year"),
      description: t("establishment_desc"),
    },
    {
      id: 3,
      img: require("../image/group.png"),
      title: t("user_count"),
      description: t("user_growth_desc"),
    },
    {
      id: 4,
      img: require("../image/pointer.png"),
      title: t("spread"),
      description: t("spread_desc"),
    },
    {
      id: 5,
      img: require("../image/mining_898304.png"),
      title: t("crypto_mining"),
      description: t("crypto_desc"),
    },
    {
      id: 6,
      img: require("../image/contract_9196743.png"),
      title: t("speculative_contracts"),
      description: t("contracts_desc"),
    },
    {
      id: 7,
      img: require("../image/oil-platform_2082691.png"),
      title: t("oil_contracts"),
      description: t("oil_desc"),
    },
    {
      id: 8,
      img: require("../image/earning_5501360.png"),
      title: t("investments"),
      description: t("investments_desc"),
    },
  ];

  // العثور على العنصر بناءً على المعرف
  const item = Data.find((d) => d.id === parseInt(id));

  return (
    <div className="Detail">
      {item ? (
        <div className="item22">
          <div>
            <img src={item.img} alt={item.title} height="200px" width="200px" />
          </div>
          <div className="textcontt">
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </div>
        </div>
      ) : (
        <p>{t("item_not_found")}</p>
      )}
    </div>
  );
};

export default Detail;
