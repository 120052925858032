import React, { useState, useContext } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import DateRangeIcon from "@mui/icons-material/DateRange"; // أيقونة لتاريخ الميلاد
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"; // أيقونة للمحفظة
import CircularProgress from "@mui/material/CircularProgress";
import "../css/sginup.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { QueryParamContext } from "./ReferralContext";
import PhoneIcon from "@mui/icons-material/Phone";

const Signin = () => {
  const { queryParam, saveQueryParam } = useContext(QueryParamContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [birth_date, setBirthDate] = useState("");
  const [wallet, setWallet] = useState("");
  const [network_number, setNetworkNumber] = useState(""); // حقل رقم الشبكة
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // تحقق محليًا من كلمة المرور
    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one numeric digit, and one special character."
      );
      setOpenSnackbar(true);
      return;
    }

    if (password !== confirm_password) {
      setErrorMessage("Passwords do not match.");
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true);
    try {
      const requestBody = {
        name,
        email,
        password,
        confirm_password,
        birth_date,
        wallet,
        token: queryParam,
        phone: phoneNumber,
        network_number, // Adding network number to the request payload
      };
      if (typeof console !== "undefined" && console.log) {
        console.log("Request Body:", requestBody);
      }
      const response = await axios.post(
        "https://money.elite-investing.com/money/api/auth/register",
        {
          name,
          email,
          password,
          confirm_password,
          birth_date,
          wallet,
          token: queryParam,
          network_number, // إضافة رقم الشبكة إلى البيانات المرسلة
          phone: phoneNumber,
        }
      );
      if (response.data) {
        setErrorMessage("Registration successful! Redirecting to login...");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/otp-page", { state: { email }, source: "signup" });
        }, 2000);
        saveQueryParam(null);
        localStorage.removeItem("queryParam");
      } else {
        setErrorMessage("Sign up failed. Please try again.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      const apiError = error.response?.data;
      if (apiError && apiError.errors) {
        setErrorMessage(apiError.errors.map((err) => err.message).join(", "));
      } else {
        setErrorMessage("Sign up failed. Please check your input.");
      }
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="page">
      <div className="containar1">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <h1>Sign up</h1>

            <TextField
              label="Name"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <TextField
              label="Email"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              label="Password"
              variant="standard"
              type="password"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <TextField
              label="Confirm Password"
              variant="standard"
              type="password"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <TextField
              label="Phone Number"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />

            {/* حقل تاريخ الميلاد */}
            <TextField
              label="Birth Date"
              variant="standard"
              type="date"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={birth_date}
              onChange={(e) => setBirthDate(e.target.value)}
              required
            />

            {/* حقل المحفظة */}
            <TextField
              label="Wallet Address"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MonetizationOnIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={wallet}
              onChange={(e) => setWallet(e.target.value)}
              required
            />

            {/* حقل رقم الشبكة */}
            <TextField
              label="Network Number"
              variant="standard"
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MonetizationOnIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
              value={network_number}
              onChange={(e) => setNetworkNumber(e.target.value)}
              required
            />

            <button type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Sign up"
              )}
            </button>
            <p>
              Do you already have an account?{" "}
              <Link to="/login" style={{ color: "white" }}>
                Login
              </Link>
            </p>
            {/* <p>
              Referral Token:{" "}
              {queryParam ? queryParam : "No referral token available"}
            </p> */}
          </form>
        </div>
        <div className="image">
          <img
            src={require("../image/rb_2149214814.png")}
            height={"97%"}
            width={"100%"}
            alt=""
          />
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={errorMessage.includes("successful") ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Signin;
