import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // لاستخدام الترجمة

const About = () => {
  const { t } = useTranslation(); // استخدام دالة الترجمة
  const Data = [
    {
      id: 1,
      img: require("../image/location.png"),
      title: t("our_location"),
      description: t("location_desc"),
    },
    {
      id: 2,
      img: require("../image/bank.png"),
      title: t("establishment_year"),
      description: t("establishment_desc"),
    },
    {
      id: 3,
      img: require("../image/group.png"),
      title: t("user_count"),
      description: t("user_growth_desc"),
    },
    {
      id: 4,
      img: require("../image/pointer.png"),
      title: t("spread"),
      description: t("spread_desc"),
    },
    {
      id: 5,
      img: require("../image/mining_898304.png"),
      title: t("crypto_mining"),
      description: t("crypto_desc"),
    },
    {
      id: 6,
      img: require("../image/contract_9196743.png"),
      title: t("speculative_contracts"),
      description: t("contracts_desc"),
    },
    {
      id: 7,
      img: require("../image/oil-platform_2082691.png"),
      title: t("oil_contracts"),
      description: t("oil_desc"),
    },
    {
      id: 8,
      img: require("../image/earning_5501360.png"),
      title: t("investments"),
      description: t("investments_desc"),
    },
  ];

  return (
    <div className="About">
      <div className="titlea">{t("about_us")}</div>
      <div className="class">
        <div className="item">
          <img
            src={require("../image/location.png")}
            height={"100px"}
            width={"100px"}
            alt="location"
          />
          <h4>{t("our_location")}</h4>
          <Link to={`/location`} className="custom-link">
            {t("learn_more")}
          </Link>
        </div>
        {Data.slice(1, 4).map((item) => (
          <div key={item.id} className="item">
            <img
              src={item.img}
              height={"100px"}
              width={"100px"}
              alt={item.title}
            />
            <h4>{item.title}</h4>
            <Link to={`/details/${item.id}`} className="custom-link">
              {t("learn_more")}
            </Link>
          </div>
        ))}
      </div>

      <h1 className="titlea">{t("our_fields")}</h1>
      <div className="class">
        {Data.slice(4).map((item) => (
          <div key={item.id} className="item">
            <img
              src={item.img}
              height={"100px"}
              width={"100px"}
              alt={item.title}
            />
            <h4>{item.title}</h4>
            <Link to={`/details/${item.id}`} className="custom-link">
              {t("learn_more")}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
