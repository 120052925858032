import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { API_URL } from "../confing";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await axios.post(`${API_URL}/api/auth/otp`, {
        email: email,
      });

      if (response.status === 200) {
        setMessage(
          "🎉 Success! Check your email for password reset instructions."
        );

        // Navigate to the ResetPassword page after showing the success message
        setTimeout(() => {
          navigate("/otp-page", { state: { email, source: "forgotPassword" } });
        }, 2000); // Wait 2 seconds before navigating
      } else {
        setError("Oops! Something went wrong. Please try again later.");
      }
    } catch (err) {
      setError(
        "🚨 Unable to process your request. Please ensure your email is correct and try again."
      );
    }
  };

  return (
    <StyledContainer>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#ff8800" }}>
          Forgot Password?
        </Typography>
        <Typography variant="body1" sx={{ color: "#bbb", marginTop: 1 }}>
          No worries! Enter your email below and we'll send you reset
          instructions.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <StyledTextField
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <StyledButton type="submit" variant="contained" fullWidth>
          Send
        </StyledButton>
      </form>

      {message && (
        <Alert
          severity="success"
          sx={{ marginTop: 3, backgroundColor: "#1f3420", color: "#80e27e" }}
        >
          {message}
        </Alert>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{ marginTop: 3, backgroundColor: "#401a1a", color: "#f44336" }}
        >
          {error}
        </Alert>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#202124",
  padding: "40px 30px",
  borderRadius: "10px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
  maxWidth: "420px",
  margin: "0 auto",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    color: "#ff8800",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#555",
    },
    "&:hover fieldset": {
      borderColor: "#ff8800",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff8800",
    },
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff8800",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px",
  borderRadius: "8px",
  textTransform: "none",
  boxShadow: "0px 4px 10px rgba(255, 136, 0, 0.3)",
  "&:hover": {
    backgroundColor: "#e57700",
    boxShadow: "0px 6px 15px rgba(255, 136, 0, 0.5)",
  },
  transition: "all 0.3s ease",
}));

export default ForgotPassword;
