import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { AuthContext } from "./Authcontext";
import Loader from "./Loader";
import { API_URL } from "../confing";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const Advantages = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [sum, setSum] = useState(0);

  const fetchAdvantages = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users/advantages/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Log the entire response to verify structure
      console.log("Full Response:", response);

      // Now access the `advantages` property of the `data` object
      const data = response.data.data; // This contains both `advantages` and `sum`

      // Ensure `advantages` is an array
      const advantages = data.advantages;
      setSum(data.sum); // Extract sum from the response

      if (!Array.isArray(advantages)) {
        console.error(
          "Expected advantages to be an array, but it's not:",
          advantages
        );
        return;
      }

      console.log("Advantages Array:", advantages); // Check the structure of the data

      // Format the data as needed for the rows
      const rowsData = advantages.map((item) => {
        const [date, time] = item.date.split("T"); // Assuming 'date' is the key holding the datetime
        return {
          id: item.id, // Use id for the row ID
          amount: item.amount,
          date, // Only extract the date part
          time, // Extract the time part
        };
      });

      setRows(rowsData); // Set the formatted rows
    } catch (error) {
      console.error("Error fetching advantages:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvantages();
  }, [token]);

  // تعريف الأعمدة
  const columns = [
    {
      field: "amount",
      headerName: `${t("ammount")}`,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      editable: false,
    },
    {
      field: "date",
      headerName: `${t("date")}`,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      editable: false,
    }, // إضافة عمود التاريخ
    // { field: "time", headerName: "Time", flex: 0.3, editable: false }, // إضافة عمود الوقت
  ];
  if (loading) return <Loader />;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          textAlign: "center",
          overflow: "hidden",
          backgroundColor: "#202124",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Box
          sx={{ width: "100%", overflowX: "auto", backgroundColor: "202124" }}
        >
          {/* Total Advantages Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "16px",
              padding: "8px 16px",
              backgroundColor: "#2a2a2a",
              borderRadius: "8px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "#ff8800",
                fontWeight: "bold",
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              {t("totalAdvantages")}: {sum}
            </Typography>
          </Box>

          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 9,
                },
              },
            }}
            pageSizeOptions={[9]}
            // checkboxSelection
            disableRowSelectionOnClick
            sx={{
              minWidth: "800px",
              width: "100%",
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#202124 !important",
                color: "#ff8800 !important",
                fontSize: "15px",
                fontWeight: "500",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#202124",
                color: "#ff8800",
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #202124",
              },
              "& .MuiCheckbox-root": {
                color: "#ff8800",
                // backgroundColor: "#202124",
                width: "100%",
                height: "100%",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "20px",
              },
              "& .super-app-theme--header": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
                outline: "none",
              },
              "@media (max-width: 600px)": {
                ".MuiDataGrid-columnHeaders": {
                  fontSize: "13px",
                },
                ".MuiDataGrid-cell": {
                  fontSize: "12px",
                  padding: "8px",
                },
              },
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </div>
  );
};

// export default Advantages;
// import React, { useEffect, useState, useContext } from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import { AuthContext } from "./Authcontext";
// import Loader from "./Loader";
// import { API_URL } from "../confing";

// const Advantages = () => {
//   const [rows, setRows] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { token } = useContext(AuthContext);

//   // const fetchAdvantages = async () => {
//   //   try {
//   //     const response = await axios.get(`${API_URL}/api/users/advantages/`, {
//   //       headers: {
//   //         Authorization: `Bearer ${token}`,
//   //       },
//   //     });

//   //     const data = response.data;

//   //     const filteredData = data.filter((item) => item.type === "1");

//   //     const rowsData = filteredData.map((item) => {
//   //       const [date, time] = item.datetime.split("T");
//   //       return {
//   //         id: item.id,
//   //         amount: item.amount,
//   //         date,
//   //         time,
//   //       };
//   //     });

//   //     setRows(rowsData);
//   //   } catch (error) {
//   //     console.error("Error fetching advantages:", error);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };
//   // console.log(token);

//   // useEffect(() => {
//   //   fetchAdvantages();
//   // }, [token]);

//   const columns = [
//     { field: "amount", headerName: "Amount", headerClassName: 'super-app-theme--header',flex: 0.2, editable: false },
//     { field: "date", headerName: "Date", headerClassName: 'super-app-theme--header',flex: 0.2, editable: false },
//     { field: "time", headerName: "Time", headerClassName: 'super-app-theme--header',flex: 0.2, editable: false },
//   ];

//   if (loading) return <Loader />;

//   return (

//     </div>
//   );
// };

// export default Advantages;
// import React, { useEffect, useState, useContext } from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import Loader from "./Loader";

// const Advantages = () => {
//   const [rows, setRows] = useState([
//     { id: 1, amount: "100", date: "2024-11-20", time: "14:30" },
//     { id: 2, amount: "200", date: "2024-11-21", time: "15:45" },
//     { id: 3, amount: "150", date: "2024-11-22", time: "16:00" },
//     // أضف بياناتك اليدوية هنا
//   ]);
//   const [loading, setLoading] = useState(false);

//   const columns = [
//     { field: "amount", headerName: "Amount", headerClassName: 'super-app-theme--header', flex: 0.2, editable: false },
//     { field: "date", headerName: "Date", headerClassName: 'super-app-theme--header', flex: 0.2, editable: false },
//     { field: "time", headerName: "Time", headerClassName: 'super-app-theme--header', flex: 0.2, editable: false },
//   ];

//   if (loading) return <Loader />;

//   return (
//     <div>

//     </div>
//   );
// };

export default Advantages;
