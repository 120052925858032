import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./Authcontext";
import { API_URL } from "../confing";
import "../css/home.css";
import CircularProgressWithLabel from "./Any";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BasicLineChart from "./Chart";
import SendAd from "./SendAd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loader from "./Loader";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import About from "./About";
import EditProfile from "./EditPro";
import { Chip } from "@mui/material";
const Home = () => {
  const { token } = useContext(AuthContext); // جلب الرمز المميز من سياق المصادقة
  const { t } = useTranslation(); // الترجمة
  const [userData, setUserData] = useState(null); // بيانات المستخدم
  const [loading, setLoading] = useState(true); // حالة التحميل
  const [error, setError] = useState(null); // حالة الخطأ

  useEffect(() => {
    const fetchData = async () => {
      // تحقق من توفر الرمز المميز
      if (!token) {
        console.error("No token available. Skipping fetch.");
        setLoading(true);
        setError(null);
        return;
      }

      try {
        console.log("Fetching data with token:", token);

        const response = await axios.get(`${API_URL}/api/auth/`, {
          headers: {
            Authorization: `Bearer ${token}`, // إرسال الرمز المميز
          },
        });

        console.log("Response Data:", response.data);
        setUserData(response.data.data); // حفظ بيانات المستخدم
        setError(null); // إعادة تعيين الخطأ
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (error.response) {
          console.error("Status Code:", error.response.status);
          console.error("Response Data:", error.response.data);

          if (error.response.status === 401) {
            setError("Network error. Please check your connection.");
          } else {
            setError("Error fetching user data. Please try again.");
          }
        } else {
          setError("Network error. Please check your connection.");
        }
      } finally {
        setLoading(false); // إيقاف حالة التحميل
      }
    };

    fetchData();
  }, [token]); // إعادة استدعاء عند تغير الرمز المميز
  if (!token) {
    return <Loader />;
  }
  // عرض حالة التحميل
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div
        style={{
          minHeight: "100vh",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f44336", // اللون الأحمر
            color: "white",
            borderRadius: "5px",
            margin: "20px auto",
            display: "inline-block",
          }}
        >
          <p>{error}</p>
        </div>
      </div>
    );
  }

  // عرض رسالة إذا كانت بيانات المستخدم غير موجودة
  if (!userData) {
    return (
      <div
        style={{
          minHeight: "100vh",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f44336", // اللون الأحمر
            color: "white",
            borderRadius: "5px",
            margin: "20px auto",
            display: "inline-block",
          }}
        >
          <p>{t("Error: User data not found. Please log in again.")}</p>
        </div>
      </div>
    );
  }

  // العرض الرئيسي عند وجود بيانات المستخدم
  return (
    <div className="home">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95%",
          alignItems: "center",
        }}
      >
        <h1 className="title">{t("home")}:</h1>
        <SendAd />
      </div>
      <div className="content">
        <div className="profilhome">
          <div className="user">
            <AccountCircleIcon
              sx={{ color: "#ff8f14", width: "200px", height: "170px" }}
            />
            <h2 className="name">{userData.name}</h2>
            {/* <p
              style={{
                fontSize: "8",
                color: "#ffffff",
                fontWeight: "600",
                display: "flex",
              }}
            >
              {userData.email}
            </p>
            */}
            <h5 className="name">{userData.email}</h5>

            <div
              className="text1"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#202124",
                padding: "15px",
                borderRadius: "10px",
                marginBottom: "15px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
              }}
            >
              {userData.level && userData.level.title ? (
                <h4
                  style={{
                    fontSize: "18px",
                    color: "#ff8f14", // Orange for valid level
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  {`Level: ${userData.level.title}`}
                </h4>
              ) : (
                <h4
                  style={{
                    fontSize: "18px",
                    color: "#ff4444", // Red for no level
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  Level: Not started yet&nbsp;
                  <span style={{ color: "#ffffff", fontWeight: "normal" }}>
                    Charge Now!!
                  </span>
                </h4>
              )}
            </div>
          </div>
          <div className="info">
            <div className="text">
              {/* User Balance */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#202124",
                  padding: "15px",
                  borderRadius: "10px",
                  marginBottom: "15px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#ff8800",
                    fontWeight: "bold",
                    marginBottom: "8px",
                  }}
                >
                  {t("user_balance")}
                </p>
                <p
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    fontWeight: "600",
                  }}
                >
                  {userData.user_amount}$
                </p>
              </div>

              {/* User Advantage */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#202124",
                  padding: "15px",
                  borderRadius: "10px",
                  marginBottom: "15px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#ff8800",
                    fontWeight: "bold",
                    marginBottom: "8px",
                  }}
                >
                  {t("user_advantage")}
                </p>
                <p
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    fontWeight: "600",
                  }}
                >
                  {userData.user_advantages}$
                </p>
              </div>
            </div>

            {/* Referral Section */}
            <Chip
              label={`Url visiting: ${userData.ref_visiting}`}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #ff8000",
                color: "#ff8000",
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            />
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                value={userData.ref_url}
                variant="standard"
                fullWidth
                InputLabelProps={{
                  style: { color: "#ff8800" },
                }}
                InputProps={{
                  readOnly: true, // للقراءة فقط
                  style: { color: "#ff8800" },
                }}
              />
              <button
                onClick={() => navigator.clipboard.writeText(userData.ref_url)}
                style={{
                  marginLeft: "10px",
                  marginTop: "0",
                  padding: "4px 4px",
                  backgroundColor: "transparent",
                  color: "White",
                  border: "none",
                  width: "40px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ContentCopyIcon />
              </button>
            </div>

            <EditProfile />
          </div>
        </div>

        {/* <div className="chart">
          <BasicLineChart />
        </div> */}
      </div>
      <About />
    </div>
  );
};

export default Home;
