import React, { useState, useContext, useEffect } from "react";
import "../css/Login.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AuthContext } from "./Authcontext"; // استيراد السياق
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Login() {
  const { t, i18n } = useTranslation();
  const { saveToken, token } = useContext(AuthContext); // استيراد دالة الحفظ
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/home");
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please fill out both fields.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `https://money.elite-investing.com/money/api/auth/login`,
        {
          email,
          password,
        }
      );

      if (response.status === 200) {
        const data = response.data.data; // الحصول على البيانات العائدة
        const token = data.token;

        if (token) {
          saveToken(token); // حفظ التوكن في السياق

          // حفظ البيانات الأخرى فقط في localStorage (دون التعديل على التوكن)
          localStorage.setItem("userData", JSON.stringify(data));

          // يمكنك إرسال هذه البيانات إلى جزء آخر من التطبيق باستخدام navigate
          navigate("/home", { state: { userData: data } }); // تمرير البيانات عبر الـ navigate
        } else {
          setError("Token not found in response.");
        }
      } else {
        setError("Invalid email or password.");
      }
    } catch (err) {
      setError("Login failed. Please try again.");
      console.error(err.response?.data || err.message);
    } finally {
      setLoading(false);
    }
  };

  if (token) {
    return null;
  }

  return (
    <div className="page">
      <div className="containar">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div>
              <h1>{t("login")}</h1>
              <p>{t("titlelog")}</p>
            </div>

            <TextField
              label={`${t("email")} :`}
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label={`${t("pass")} :`}
              variant="standard"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiInputBase-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root": { color: "#ff8f14" },
                "& .MuiInputLabel-root.Mui-focused": { color: "#ff8f14" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ff8f14",
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff",
                },
                "& .MuiInput-underline:after": { borderBottomColor: "#ff8f14" },
              }}
              InputLabelProps={{
                style: {
                  color: "#ff8f14",
                  direction: i18n.language === "ar" ? "rtl" : "ltr", // تغيير الاتجاه بناءً على اللغة
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "#ff8f14" }} />
                  </InputAdornment>
                ),
                style: {
                  textAlign: i18n.language === "ar" ? "right" : "left", // محاذاة النص بناءً على اللغة
                },
              }}
            />

            {error && (
              <Typography
                style={{ color: "red", textAlign: "center", margin: "10px 0" }}
              >
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              style={{
                backgroundColor: "#ff8f14",
                marginTop: "16px",
                color: "white",
                borderRadius: "25px",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("login")
              )}
            </Button>

            <p>
              {t("dont")}
              <Link
                to="/signup"
                style={{ color: "white", textDecoration: "none" }}
              >
                {t("SignUp")}
              </Link>
            </p>
            <p>
              <Link
                to="/forgot-password"
                style={{ color: "white", textDecoration: "none" }}
              >
                {t("ForgotPassword")}
              </Link>
            </p>
          </form>
        </div>
        <div className="image">
          <img
            src={require("../image/rb_2149214814.png")}
            height={"80%"}
            width={"90%"}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
