import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Login from "./components/Login";
import Signin from "./components/Signin";
import SendNewPassword from "./components/SetNewpass";
import SideBar from "./components/SideBar";
import Home from "./components/Home";
import { AuthProvider } from "./components/Authcontext";
import ProtectedRoute from "./components/ProtectedRoute";
import Withdraws from "./components/Withdraws";
import AdvantagesTable from "./components/AdvantagesTable";
import Level from "./components/Level";
import Charge from "./components/Charge";
import "./App.css";
import Datile from "./components/Datile";
import Location from "./components/Location";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./i18n"; // استيراد إعداد i18n
import { useTranslation } from "react-i18next";
import {
  QueryParamProvider,
  QueryParamContext,
} from "./components/ReferralContext";
import OTPPage from "./components/Otp";
import ForgotPassword from "./components/Forget";
import SetNewpass from "./components/SetNewpass";
// إنشاء الثيم مع الخط المطلوب
const theme = createTheme({
  typography: {
    fontFamily: "'Tajawal', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Tajawal', sans-serif",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "'Tajawal', sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "'Tajawal', sans-serif",
        },
      },
    },
  },
});
function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          {/* Move QueryParamProvider inside Router */}
          <QueryParamProvider>
            <MainApp />
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

function MainApp() {
  const location = useLocation();
  const { queryParam } = useContext(QueryParamContext);
  return (
    <div className="App">
      {location.pathname !== "/login" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/set-pass" &&
        location.pathname !== "/set-new-password" &&
        location.pathname !== "/otp-page" && <SideBar />}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/home" element={<ProtectedRoute element={Home} />} />
        <Route path="/set-new-password" element={<SendNewPassword />} />

        <Route
          path="/advantages"
          element={<ProtectedRoute element={AdvantagesTable} />}
        />
        <Route
          path="/withdraws"
          element={<ProtectedRoute element={Withdraws} />}
        />
        <Route path="/levels" element={<ProtectedRoute element={Level} />} />
        <Route path="/charge" element={<ProtectedRoute element={Charge} />} />
        <Route
          path="/details/:id"
          element={<ProtectedRoute element={Datile} />}
        />
        <Route
          path="/location"
          element={<ProtectedRoute element={Location} />}
        />
        <Route
          path="/forgot-password"
          element={<ProtectedRoute element={ForgotPassword} />}
        />
        <Route path="/otp-page" element={<OTPPage />} />
        <Route
          path="/set-pass"
          element={<ProtectedRoute element={SetNewpass} />}
        />
      </Routes>
    </div>
  );
}

export default App;
