import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { API_URL } from "../confing";
import { useLocation, useNavigate } from "react-router-dom";
const SetNewPassword = () => {
  const location = useLocation();
  const { email, otp } = location.state || {}; // Get email and otp from state
  const navigate = useNavigate(); // Initialize navigate
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    const requestBody = {
      email: email, // Pass email
      otp: otp, // Pass otp
      password: password,
      confirmPassword: confirmPassword,
    };

    // Print the request body to the console
    console.log("Request Body:", requestBody);

    try {
      const response = await axios.put(
        `${API_URL}/api/auth/forget-password`,
        requestBody
      );

      if (response.status === 201) {
        setMessage("🎉 Password reset successfully! You can now log in.");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        setError("Oops! Something went wrong. Please try again.");
      }
    } catch (err) {
      setError("🚨 Unable to reset your password. Please try again later.");
    }
  };

  return (
    <StyledContainer>
      <Box sx={{ textAlign: "center", marginBottom: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#ff8800" }}>
          Reset Password
        </Typography>
        <Typography variant="body1" sx={{ color: "#bbb", marginTop: 1 }}>
          Create a new password for your account.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <StyledTextField
          label="New Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <StyledTextField
          label="Confirm New Password"
          type="password"
          fullWidth
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <StyledButton type="submit" variant="contained" fullWidth>
          Reset Password
        </StyledButton>
      </form>

      {message && (
        <Alert
          severity="success"
          sx={{ marginTop: 3, backgroundColor: "#1f3420", color: "#80e27e" }}
        >
          {message}
        </Alert>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{ marginTop: 3, backgroundColor: "#401a1a", color: "#f44336" }}
        >
          {error}
        </Alert>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#202124",
  padding: "40px 30px",
  borderRadius: "10px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
  maxWidth: "420px",
  margin: "0 auto",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    color: "#ff8800",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#555",
    },
    "&:hover fieldset": {
      borderColor: "#ff8800",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ff8800",
    },
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff8800",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px",
  borderRadius: "8px",
  textTransform: "none",
  boxShadow: "0px 4px 10px rgba(255, 136, 0, 0.3)",
  "&:hover": {
    backgroundColor: "#e57700",
    boxShadow: "0px 6px 15px rgba(255, 136, 0, 0.5)",
  },
  transition: "all 0.3s ease",
}));

export default SetNewPassword;
