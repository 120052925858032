import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Alert,
  CircularProgress,
  Link,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { API_URL } from "../confing";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // استخدام الترجمة

const OTPPage = () => {
  const theme = useTheme();
  const { t } = useTranslation(); // دالة الترجمة
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [resendDisabled, setResendDisabled] = useState(false);
  const location = useLocation();
  const email = location.state?.email;
  const source = location.state?.source;
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Email:", email); // Debugging
    console.log("Source:", source); // Debugging

    if (source === "forgotPassword") {
      navigate("/set-new-password", { state: { email, otp } });
      return;
    }

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post(`${API_URL}/api/auth/otp/verify`, {
        otp,
        email,
      });

      setSuccessMessage(t("otp_verified"));
      setTimeout(() => {
        navigate(source === "signup" ? "/login" : "/");
      }, 2000); // Default fallback navigation
    } catch (error) {
      setErrorMessage(t("otp_error") || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };  

  const handleResend = async () => {
    setResendDisabled(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      await axios.post(`${API_URL}/api/auth/otp`, { email });
      setSuccessMessage(t("otp_resent"));
      setTimeout(() => setResendDisabled(false), 60000);
    } catch (error) {
      setErrorMessage(t("otp_resend_error") || "Failed to resend OTP.");
      setResendDisabled(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#202124",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#333",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ color: "#ffffff", fontWeight: "bold" }}
          >
            {t("verify_account")}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            gutterBottom
            sx={{ color: "#aaa" }}
          >
            {t("otp_sent_to")} <strong>{email}</strong>
          </Typography>

          {errorMessage && (
            <Alert
              severity="error"
              icon={<ErrorIcon fontSize="inherit" />}
              sx={{ mb: 2, fontSize: "0.9rem" }}
            >
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert
              severity="success"
              icon={<CheckCircleIcon fontSize="inherit" />}
              sx={{ mb: 2, fontSize: "0.9rem" }}
            >
              {successMessage}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label={t("otp_code")}
              variant="outlined"
              margin="normal"
              required
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              InputLabelProps={{
                style: { color: "#ff8800" },
              }}
              InputProps={{
                style: { color: "#ffffff" },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 2,
                fontWeight: "bold",
                backgroundColor: "#ff8800",
                "&:hover": {
                  backgroundColor: "#ff7700",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("verify")
              )}
            </Button>
          </form>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link
              component="button"
              disabled={resendDisabled}
              onClick={handleResend}
              sx={{
                fontSize: "0.9rem",
                color: resendDisabled ? "#777" : "#ffffff",
                textDecoration: resendDisabled ? "none" : "underline",
              }}
            >
              {t("resend_otp")}
            </Link>
          </Box>
          {resendDisabled && (
            <Typography variant="caption" color="#aaa">
              {t("wait_resend")}
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default OTPPage;
