import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { AuthContext } from "./Authcontext";
import Loader from "./Loader";
import { API_URL } from "../confing";
import { Chip } from "@mui/material"; // Import Chip for status
import { useTranslation } from "react-i18next";

const TableofWithdraws = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sum, setSum] = useState(0); // Store the sum from API
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const fetchWithdraws = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users/withdraws/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Full Response:", response);

      const data = response.data.data; // Contains `withdraws` and `sum`

      // Validate `withdraws` is an array
      if (!Array.isArray(data.withdraws)) {
        console.error(
          "Expected withdraws to be an array, but it's not:",
          data.withdraws
        );
        return;
      }

      // Set sum from API response
      setSum(data.sum);

      // Map API data to rows
      const rowsData = data.withdraws.map((item) => {
        const [date, time] = item.date.split("T");
        return {
          id: item.id,
          amount: item.amount,
          date, // Only extract the date part
          time,
          status: item.status, // Include status
        };
      });

      setRows(rowsData);
    } catch (error) {
      console.error("Error fetching withdraws:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdraws();
  }, [token]);

  // Columns for DataGrid
  const columns = [
    {
      field: "amount",
      headerName: `${t("amount")}`,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      editable: false,
    },
    {
      field: "date",
      headerName: `${t("date")}`,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      editable: false,
    },
    {
      field: "status",
      headerName: `${t("status")}`,
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      editable: false,
      renderCell: (params) => {
        // Display status with a styled Chip
        return (
          <Chip
            label={params.value} // The status value
            style={{
              backgroundColor: "transparent", // Transparent background
              border: "1px solid #ff8000", // Orange border
              color: "#ff8000", // Orange text
              borderRadius: "8px",
              marginBottom: "auto",
            }}
          />
        );
      },
    },
  ];

  if (loading) return <Loader />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          textAlign: "center",
          overflow: "hidden",
          backgroundColor: "#202124",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        {/* Display total sum */}
        <Typography
          variant="h6"
          sx={{
            color: "#ff8800",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px",
            marginBottom: "20px",
          }}
        >
          {t("totalAdvantages")}: {sum}
        </Typography>

        {/* DataGrid Component */}
        <Box
          sx={{ width: "100%", overflowX: "auto", backgroundColor: "#202124" }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 9,
                },
              },
            }}
            pageSizeOptions={[9]}
            disableRowSelectionOnClick
            sx={{
              minWidth: "800px",
              width: "100%",
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#202124 !important",
                color: "#ff8800 !important",
                fontSize: "15px",
                fontWeight: "500",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#202124",
                color: "#ff8800",
                "&:hover": {
                  backgroundColor: "#F0F0F0",
                },
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #202124",
              },
              "& .MuiCheckbox-root": {
                color: "#ff8800",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-root": {
                border: "none",
                borderRadius: "20px",
              },
              "& .super-app-theme--header": {
                backgroundColor: "#202124 !important",
                color: "ff8800",
              },
              "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
                outline: "none",
              },
              "@media (max-width: 600px)": {
                ".MuiDataGrid-columnHeaders": {
                  fontSize: "13px",
                },
                ".MuiDataGrid-cell": {
                  fontSize: "12px",
                  padding: "8px",
                },
              },
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default TableofWithdraws;
